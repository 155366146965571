import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import {useEffect} from "react";
import ChangeLanguage from "../components/changeLanguage";

const DigitalCavePage = ({location}) => {

    React.useEffect(() => {
        const scrollContainer = document.querySelector('.site-wrapper');

        if(scrollContainer) {
            scrollContainer.scrollTop = 0;
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);

    return (
        <>
            <Helmet>
                <title>Digital Cave | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
            </Helmet>

            <section className="installation-img-section">
                <img className="" src="/static/img/8_DigitalCave.jpg" alt=""/>
            </section>

            <section className="site-section site-section--installation">
                <ChangeLanguage location={location}/>
                <div className="content-section" style={ { "--width": "72rem" } }>

                    <h2 className="content-section__title">DIGITAL CAVE</h2>

                    <div className="content-section__text">
                        <p>DIGITAL CAVE is a LED light tunnel. The installation consists of 8,200 LEDs that illuminate in their individual sequences, generating bewildering patterns that bend your perceptions of space.</p>
                    </div>


                    <br/>

                    <div className="green">
                        <h2 className="content-section__title">ARTIST — TRIBE</h2>
                        <div className="content-section__text">
                            <p>TRIBE is an Audiovisual art collective founded in 2017 in Saint-Petersburg. Their works include site-specific immersive light and sound installations, and has been featured worldwide: Times Square Screen, New York, USA; Ningbo Exebition, Ningbo, China; GAMMA Festival, Saint-Petersburg, Russia; Pixels Fest, Ekaterinburg, NUR Festival, Kazan, Russia.</p>
                        </div>
                    </div>

                    <MainSectionLogo className="content-section__logo" />

                </div>
            </section>
        </>
    )
}

export default DigitalCavePage;
