import * as React from "react";
import { Link } from "gatsby";

const ChangeLanguage = ({location}) => {

    function getCurLang()
    {
        let pathName = location.pathname;
        if (pathName.indexOf('/cn/') !== -1) {
            return 'cn';
        }
        return 'en';
    }

    function getEn()
    {
        let curLang = getCurLang();
        if (curLang === 'en') {
            return <span className="change-lang--item active">EN</span>
        } else {
            let link = location.pathname.replace('\/cn\/', '/');
            return <Link to={link} className="change-lang--item">EN</Link>
        }
    }

    function getCn()
    {
        let curLang = getCurLang();
        if (curLang === 'cn') {
            return <span className="change-lang--item active">中</span>
        } else {
            let link = location.pathname + 'cn/';
            return <Link to={link} className="change-lang--item">中</Link>
        }
    }

    return (
        <div className="change-lang">
            {getEn()}
            <span className="vl"></span>
            {getCn()}
        </div>
    )
}

export default ChangeLanguage;